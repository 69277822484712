
// Frontend js init
$(document).ready(function() {
  burgermenu();
  // console.log('document ready');
// var fadeTime = 50;
// var interval = 500;
//
// // Blink 140
// var game140 = {};
// $( '.link-140' ).mouseout(function() {
//   clearInterval(game140);
//   })
//   .mouseover(function() {
//     $this = this;
//     game140 = setInterval(function() {
//       $( $this ).fadeOut( fadeTime );
//       $( $this ).fadeIn( fadeTime );
//     }, interval);
//   });
//
// // Blink THOTH
// var gameThoth = {};
// $( '.link-thoth' ).mouseout(function() {
//   clearInterval(gameThoth);
//   })
//   .mouseover(function() {
//     $this = this;
//     gameThoth = setInterval(function() {
//       $( $this ).fadeOut( fadeTime );
//       $( $this ).fadeIn( fadeTime );
//     }, interval);
//   });
//
//   // Blink games
//   var games = {};
//   $( '.menu-button-games' ).mouseout(function() {
//     clearInterval(games);
//     })
//     .mouseover(function() {
//       $this = this;
//       games = setInterval(function() {
//         $( $this ).fadeOut( fadeTime );
//         $( $this ).fadeIn( fadeTime );
//       }, interval);
//     });
//
//     // Blink about
//     var about = {};
//     $( '.menu-button-about' ).mouseout(function() {
//       clearInterval(about);
//       })
//       .mouseover(function() {
//         $this = this;
//         about = setInterval(function() {
//           $( $this ).fadeOut( fadeTime );
//           $( $this ).fadeIn( fadeTime );
//         }, interval);
//       });
//
//     // Blink contact
//     var contact = {};
//     $( '.menu-button-contact' ).mouseout(function() {
//       clearInterval(contact);
//       })
//       .mouseover(function() {
//         $this = this;
//         contact = setInterval(function() {
//           $( $this ).fadeOut( fadeTime );
//           $( $this ).fadeIn( fadeTime );
//         }, interval);
//       });
});

var burgermenu = function( ) {
  $('.burgermenu-icon').click(function(){
    if($('.burger-menu-wrapper').hasClass('display-burger-menu')){
      $('.burger-menu-wrapper').removeClass('display-burger-menu');
      $('.burgermenu-icon-exit').css('display', 'none');
      $('.burgermenu-icon-start').css('display', 'block');
    } else {
      $('.burger-menu-wrapper').addClass('display-burger-menu');
      $('.burgermenu-icon-start').css('display', 'none');
      $('.burgermenu-icon-exit').css('display', 'block');
    }
  });
}
